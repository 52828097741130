import React, { useEffect, useState, useRef } from "react";
import useCustomLogout from "./hook/useLogout";
import usePurchaseGold from "./hook/usePurchaseGold";
import useDeposit from "./hook/useDeposit";
import { usePrivy, useLinkAccount, useOAuthTokens } from "@privy-io/react-auth";
import useWithdraw from "./hook/useWithdraw";
import useTwitter from "./hook/useTwitter";

const GameComponent = () => {
  const { user, unlinkTwitter } = usePrivy();
  const { postTweet, postTweetWithMedia } = useTwitter();
  const { customLogout } = useCustomLogout();
  const { purchaseGold } = usePurchaseGold();
  const { handleFund } = useDeposit();
  const { handleWithdraw } = useWithdraw();
  const { exportWallet } = usePrivy();
  const { updateTwitterInfo } = useTwitter();
  const { linkTwitter } = useLinkAccount({
    onSuccess: async (linkedAccount) => {
      await updateTwitterInfo(linkedAccount.twitter);
    },
  });

  const twitterSubject = user?.twitter?.subject || null;

  const { reauthorize } = useOAuthTokens({
    onOAuthTokenGrant: async (tokens, context) => {
      if (tokens.provider === "twitter") {
        if (!localStorage.getItem("reauth_done")) {
          console.log("reauthorize user", context.user, tokens);
          localStorage.setItem("twitter_access_token", tokens.accessToken);
          localStorage.setItem("twitter_refresh_token", tokens.refreshToken);
          
          localStorage.setItem("reauth_done", "true");

          if (localStorage.getItem("fish_share_text") != null) {
            console.log("share again");
            await postTweetWhenLoggedIn(localStorage.getItem("fish_share_text"));
          }
        } else {
          console.log("Reauthorize already done, skipping...");
        }
      }
    },
  });

  const [containerStyle, setContainerStyle] = useState({
    height: "100dvh",
    width: "100vw",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const fishQuality = {
    1: "Common",
    2: "Rare",
    3: "Epic",
    4: "Legendary",
  };

  const iframeRef = useRef(null);
  const [gameState, setGameState] = useState(() => {
    const savedState = localStorage.getItem("cocosGameState");
    return savedState ? JSON.parse(savedState) : null;
  });

  useEffect(() => {
    return () => {
      localStorage.removeItem("reauth_done");
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("[React-handleMessage]", event);
      console.log(event.data.type);
      console.log(event.data);
      if (event.source === iframeRef.current?.contentWindow) {
        console.log("Nhận được sự kiện từ Cocos:", event.data);
        localStorage.setItem("cocosGameState", JSON.stringify(event.data));
        setGameState(event.data);
        if (event.data.type === "buttonLogOutClicked") {
          cocosLogoutBtnClick(event.data.data);
        } else if (event.data.type === "buttonBuyCoinClicked") {
          cocosExchangeGoldBtnClick(event.data.data);
        } else if (event.data.type === "buttonDepositClicked") {
          cocosDepositBtnClick(event.data.message);
        } else if (event.data.type === "buttonExportWalletClicked") {
          cocosExportWalletBtnClick(event.data.data);
        } else if (event.data.type === "buttonWithdrawClicked") {
          cocosWithdrawBtnClick(event.data.address, event.data.amountETH);
        } else if (event.data.type === "buttonLinkTwitterClicked") {
          cocosLinkTwitterBtnClick(event.data.data);
        } else if (event.data.type === "buttonUnlinkTwitterClicked") {
          cocosUnlinkTwitterBtnClick(event.data.data);
        } else if (event.data.type === "buttonShareFishClicked") {
          cocosShareFishBtnClick(event.data.data);
        } else {
          const authToken = localStorage.getItem("fishAuth");
          const userId = localStorage.getItem("userId");
          const beAddress = localStorage.getItem("fish_be_endpoint");
          iframeRef.current.contentWindow.postMessage(
            { authToken: authToken, userId: userId, beAddress: beAddress },
            "*"
          );
          console.log("[React-postMessage] token: " + authToken);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Gửi trạng thái đã lưu vào game sau khi iframe đã load
    const onIframeLoad = () => {
      if (gameState && iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(
          { type: "RESTORE_STATE", state: gameState },
          "*"
        );
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", onIframeLoad);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
      if (iframe) {
        iframe.removeEventListener("load", onIframeLoad);
      }
    };
  }, [gameState]);

  const refreshDataInventory = async () => {
    if (iframeRef.current) {
      console.log(">>RefreshDataInventoryEvent");
      iframeRef.current.contentWindow.postMessage(
        { type: "refreshDataInventory" },
        "*"
      );
    }
  };

  const notifyReact = async (data) => {
    if (gameState && iframeRef.current) {
      console.log(">>NotifyReact");
      iframeRef.current.contentWindow.postMessage(
        {
          type: "notifyReact",
          message: data.message,
          isSuccess: data.isSuccess,
        },
        "*"
      );
    }
  };

  const cocosLogoutBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    customLogout();
  };

  const cocosExchangeGoldBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    await purchaseGold(data);
    refreshDataInventory();
  };

  const cocosDepositBtnClick = async (amount) => {
    console.log("Button in Cocos was clicked!", amount);
    await handleFund(amount);
    refreshDataInventory();
  };

  const cocosExportWalletBtnClick = (data) => {
    console.log("Button in Cocos was clicked!", data);
    exportWallet();
  };

  const cocosLinkTwitterBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    if (!user.twitter?.subject) {
      linkTwitter();
    } else {
      console.warn("Tài khoản Twitter đã được liên kết.");
    }
  };

  const cocosUnlinkTwitterBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    try {
      await unlinkTwitter(twitterSubject);
      await updateTwitterInfo(null);
      refreshDataInventory();
      console.log("Unlink và cập nhật thành công!");
    } catch (error) {
      console.error("Unlink Twitter thất bại:", error);
    }
  };

  const cocosWithdrawBtnClick = async (address, amount) => {
    console.log("Button in Cocos was clicked!", address);
    await handleWithdraw(address, amount);
    refreshDataInventory();
  };

  const cocosShareFishBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    await handlePostTweet(data);
    refreshDataInventory();
  };

  const handlePostTweet = async (fishData) => {
    try {
      const tweetText = `I caught a ${fishQuality[fishData.quality]} Fish - ${
        fishData.fishName
      } fish ✌️ \nJoin with me https://fishingfrenzy.co/ #fishing_frenzy`;
      if (localStorage.getItem("twitter_access_token") == null) {
        notifyReact({
          message: "You need to login X to share your fish. Redirecting...",
          isSuccess: false,
        });
        localStorage.setItem("fish_share_text", tweetText);
        await reauthorize({ provider: "twitter" });
      } else {
        postTweetWhenLoggedIn(tweetText);
      }
    } catch (error) {
      console.error("Post tweet thất bại:", error);
    }
  };

  const postTweetWhenLoggedIn = async (tweetText) => {
    try {
      const response = await postTweet(tweetText);
      if (response.status == 200) {
        notifyReact({
          message: "Your fish has been shared to X!",
          isSuccess: true,
        });
        localStorage.removeItem("fish_share_text");
        console.log("fish_share_removed", localStorage.getItem("fish_share"));
      }
    } catch (error) {
      notifyReact({
        message: "Failed to share your fish",
        isSuccess: false,
      });
    }
  };

  return (
    <div style={containerStyle}>
      {/* <button
        className="Deposit-button"
        onClick={() => {
          handlePostTweet({
            fishName: "abc",
            quality: 1,
          });
        }}
      >
        Log Twitter subject
      </button> */}
      <iframe
        ref={iframeRef}
        src="/cocos/index.html"
        title="Cocos Game"
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default GameComponent;
