import { usePrivy } from "@privy-io/react-auth";
import axios from "axios";
import React, { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";
import GameComponent from "../GameComponent";
import useCustomLogout from "../hook/useLogout";
import useTwitter from "../hook/useTwitter";

function Game() {
  const { user, authenticated } = usePrivy();
  const { customLogout } = useCustomLogout();
  const navigate = useNavigate();
  const isGuest = localStorage.getItem("isGuest") === "true";
  const imgBase64Sample =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=";

  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("fishAuth");

  const isPWA = () => {
    return window.matchMedia("(display-mode: standalone)").matches;
  };

  useEffect(() => {
    const performLogout = async () => {
      if (!isDesktop && !isPWA()) {
        customLogout();
      }

      try {
        const { data } = await axios.get(`${urlEndpoint}/users/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (data && !data?.isEnteredRefCode) {
          navigate("/ref-code");
        }
      } catch (error) {
        console.log("Game screen error", error);
      }
    };
    performLogout();
  }, [navigate]);

  const numAccounts = user?.linkedAccounts?.length || 0;
  const canRemoveAccount = numAccounts > 1;

  const twitterSubject = user?.twitter?.subject || null;

  return authenticated || isGuest ? (
    <div className="Game-container">
      <div>
        <GameComponent /> {/* Component game */}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Game;
