import "../App.css";
import { useWallets, getEmbeddedConnectedWallet, addRpcUrlOverrideToChain } from "@privy-io/react-auth";
import { base, baseSepolia } from "viem/chains"

let baseCustomRPC;

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  baseCustomRPC = addRpcUrlOverrideToChain(base, process.env.REACT_APP_RPC_BASE_SEPOLIA);
}
else {
  baseCustomRPC = addRpcUrlOverrideToChain(baseSepolia, process.env.REACT_APP_RPC_BASE_SEPOLIA);
}

function useDeposit() {
  const { wallets } = useWallets();
  const wallet = getEmbeddedConnectedWallet(wallets);

  const handleFund = async(amount) => {
    console.log("handleFund", amount)
    await wallet.fund({
      chain: baseCustomRPC,
      amount: amount, // ETH
    });
  }

  return { handleFund };
}

export default useDeposit;
