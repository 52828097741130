import {
  getAccessToken,
  useLogin,
  usePrivy,
  useSetWalletRecovery,
} from "@privy-io/react-auth";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";
import { getDeviceId, removeDeviceId } from "../utils";

function useCustomLogin() {
  const { ready, authenticated, logout } = usePrivy();
  const { setIsAuthenticated, setErrorMessage, setIsGuest } =
    useGlobalContext();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const next = search.get("next") ? window.atob(search.get("next")) : null;
  const deviceId = getDeviceId();
  const { setWalletRecovery } = useSetWalletRecovery({
    onError: async (error) => {
      handleLoginError(error);
    },
  });

  const { login } = useLogin({
    onError: async () => {
      logout();
    },
    onOAuthLoginComplete: async (oAuthTokens) => {
      console.log(">>>>>>>>>>>>>>>>>>>>>>OAuth Token");
      console.log("OAuth Token", oAuthTokens);
      console.log("OAuth Access Token:", oAuthTokens.accessToken);
      console.log("OAuth Refresh Token:", oAuthTokens.refreshToken);

      // Lưu trữ các thông tin cần thiết vào localStorage
      if (oAuthTokens.provider == "twitter") {
        localStorage.setItem("twitter_access_token", oAuthTokens.accessToken);
        localStorage.setItem("twitter_refresh_token", oAuthTokens.refreshToken);
      }
    },
    onComplete: async (user, linkedAccount) => {
      console.log("user", user);
      console.log("linkedAccount", linkedAccount);
      const privyToken = await getPrivyAccessToken(); // Lấy access token từ user object
      let url_login = urlEndpoint + "/auth/login";
      try {
        if (!(await alreadyHasUserOwnedRecovery(user))) {
          await setWalletRecovery();
        }
        const { data } = await axios.post(
          url_login,
          JSON.stringify({
            walletAddress: user?.wallet?.address,
            deviceId,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "x-privy-token": privyToken,
            },
          }
        );

        removeDeviceId();
        console.log(data?.tokens.access.token);
        localStorage.setItem("fishAuth", data?.tokens.access.token);
        localStorage.setItem("userId", data?.user.id);
        console.log("Logged in FISH_BE");
        setIsAuthenticated(authenticated);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("fish_be_endpoint", urlEndpoint);
        if (data.user?.isEnteredRefCode) {
          navigate(next ?? "/");
        } else {
          navigate("/ref-code");
        }
      } catch (error) {
        handleLoginError(error);
      }
    },
  });

  const getPrivyAccessToken = async () => {
    const accessToken = await getAccessToken();
    console.log(accessToken);
    return accessToken;
  };

  const guestLogin = async () => {
    let url_login = urlEndpoint + "/auth/guest-login";
    try {
      const { data } = await axios.post(
        url_login,
        JSON.stringify({ deviceId }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(data?.tokens.access.token);
      localStorage.setItem("fishAuth", data?.tokens.access.token);
      localStorage.setItem("userId", data?.user.id);
      console.log("Logged in FISH_BE");
      setIsAuthenticated(authenticated);
      setIsGuest(true);
      localStorage.setItem("isGuest", true);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("fish_be_endpoint", urlEndpoint);
      if (data.user?.isEnteredRefCode) {
        navigate(next ?? "/");
      } else {
        navigate("/ref-code");
      }
    } catch (error) {
      setIsGuest(false);
      localStorage.setItem("isGuest", false);
      handleLoginError(error);
    }
  };

  const alreadyHasUserOwnedRecovery = async (user) => {
    if (!user) {
      throw new Error("user is not provided");
    }
    const embeddedWallet = user.linkedAccounts.find(
      (account) =>
        account.type === "wallet" && account.walletClientType === "privy"
    );
    if (!embeddedWallet) {
      throw new Error("No embedded wallet found");
    }
    return embeddedWallet.recoveryMethod !== "privy";
  };

  const handleLoginError = async (error) => {
    console.log(error);
    logout();
    setIsAuthenticated(false);
    localStorage.removeItem("fishAuth");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthenticated");
    setErrorMessage(error.response?.data.message);
    navigate("/login");
  };

  return { login, guestLogin, ready };
}

export default useCustomLogin;
