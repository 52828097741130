import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import fish from "../assets/images/icon.png";
import sushi from "../assets/images/sushi.png";
import { SYSTEM } from "../constant";
import { useGlobalContext } from "../context";
import useCustomLogout from "../hook/useLogout";

function ReferenceCode() {
  const { setErrorMessage } = useGlobalContext();
  const { customLogout } = useCustomLogout();
  const [search, setSearchParams] = useSearchParams();
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [error, setError] = useState("");
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const codeQuery = search.get("code") || "";
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("fishAuth");
  const [items, setItems] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(`${urlEndpoint}/users/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (data && data?.isEnteredRefCode) {
          navigate("/");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    const getItems = async () => {
      try {
        const { data } = await axios.get(`${urlEndpoint}/items?search=sushi`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setItems(data.results);
      } catch (error) {
        if (error?.response?.status === 401) {
          setErrorMessage("Your session has expired.");
          customLogout();
        }
      }
    };

    fetchUserData();
    getItems();
  }, []);

  useEffect(() => {
    const getRefCodeDetail = async () => {
      try {
        const { data } = await axios.get(
          `${urlEndpoint}/reference-code/detail?code=${codeQuery}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setShowContinue(!!data);
        setDetail(data);
        if (!data) {
          setError("Invalid code.");
          setSearchParams({ code: "" });
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          setErrorMessage("Your session has expired.");
          customLogout();
        }
        setError(
          error?.response?.data?.message ||
            "An error occurred while verifying the code"
        );
        setSearchParams({ code: "" });
      }
    };

    if (codeQuery?.length === 6) {
      getRefCodeDetail();
    } else {
      setShowContinue(false);
    }
  }, [codeQuery]);

  const handleSubmit = useCallback(async () => {
    const url_verifyCode = `${urlEndpoint}/reference-code/verify?code=${codeQuery}`;

    try {
      setLoading(true);
      await axios.post(
        url_verifyCode,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      setIsVerifySuccess(!isVerifySuccess);
    } catch (error) {
      if (error?.response?.status === 401) {
        setErrorMessage("Your session has expired.");
        customLogout();
      }
      setIsVerifySuccess(false);
      setError(
        error?.response?.data?.message ||
          "An error occurred while verifying the code"
      );
    } finally {
      setLoading(false);
    }
  }, [codeQuery]);

  const handleClickPlay = () => {
    navigate("/");
  };

  return (
    <div className="bg-reference-code">
      <Container className="m-auto d-flex justify-content-center">
        {isVerifySuccess && (
          <Row className="ref-code-wrapper ">
            <Col className="d-flex flex-column align-items-center gap-4">
              <h5 className="sub-title">
                {detail?.createdBy === SYSTEM
                  ? "You have been invited"
                  : `Welcome! You have been referred by [${detail?.createdBy}]`}
              </h5>
              <span className="sub-title">Play now and get rewards!</span>

              <button onClick={handleClickPlay} className="play-button">
                <span>Play</span>
              </button>
            </Col>
          </Row>
        )}
        {!isVerifySuccess && (
          <Row className="ref-code-wrapper ">
            <Col className="d-flex flex-column align-items-center gap-5">
              <div className="logo">
                <img src={fish} alt="" />
              </div>
              <h3 className="mb-2 header-title">Fishing Frenzy</h3>
              {!showContinue && (
                <div>
                  <h5 className="sub-title">Phase 1: Reel of Fortune</h5>
                  <span className="sub-title">
                    Enter referral code to start fish-to-earn
                  </span>
                </div>
              )}
              <Form
                className="d-flex flex-column gap-3 align-items-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group>
                  <OTPInput
                    shouldAutoFocus={true}
                    onChange={(e) => {
                      setError("");
                      setSearchParams({ code: e.toUpperCase() });
                    }}
                    value={codeQuery}
                    containerStyle={containerStyle()}
                    inputStyle={inputStyle()}
                    numInputs={6}
                    inputType="text"
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="text"
                        className="pin-input"
                        autoComplete="new-password"
                        onInput={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                        }}
                      />
                    )}
                    onPaste={(e) =>
                      setSearchParams({
                        code: e.clipboardData
                          .getData("text")
                          .toUpperCase()
                          .slice(0, 6),
                      })
                    }
                  />
                  {!!error && (
                    <Form.Text
                      className="text-danger"
                      style={{
                        fontFamily: '"WixMadeforBold", system-ui',
                      }}
                    >
                      {error}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form>
              {!showContinue && (
                <span className="sub-title-discord">
                  Need Code? Join{" "}
                  <a
                    className="sub-title-discord"
                    target="_blank"
                    href="https://discord.gg/unchartedgg"
                    title="Discord"
                  >
                    Discord
                  </a>
                </span>
              )}

              {showContinue && (
                <button onClick={handleSubmit} className="continue-button">
                  <span>{loading ? "...Loading" : "Continue"}</span>
                </button>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default ReferenceCode;

export const containerStyle = () => ({
  width: "auto",
  height: "50px",
  padding: "0 12px",
  fontSize: "32px",
  letterSpacing: "14.4px",
  fontWeight: "500",
  lineHeight: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid rgb(221, 183, 144)",
  borderRadius: "8px",
  transition: "color 0.3s",
  backgroundColor: "white",
  verticalAlign: "middle",
});

export const inputStyle = () => ({
  border: "none",
  outline: "none",
  fontSize: "32px",
  fontWeight: "600",
  textAlign: "center",
  height: "100%",
  flex: 1,
  width: "42px",
  margin: "0 2px",
  backgroundColor: "white",
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  fontFamily: '"WixMadeforBold", system-ui',
  padding: 0,
});
