import { v4 as uuid } from "uuid";
const DEVICE_ID_KEY = "app-name-device-id";

export const getDeviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY) || "";
  if (!deviceId) {
    deviceId = uuid();
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};

export const removeDeviceId = () => {
  localStorage.removeItem(DEVICE_ID_KEY);
};

export const getPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/android/i.test(userAgent)) {
    return "Android";
  } else if (/iPad|iPhone|iPod|MAC/.test(userAgent) && !window.MSStream) {
    return "iOS";
  } else if (/windows/i.test(userAgent)) {
    return "Windows";
  } else {
    return "Other";
  }
};
