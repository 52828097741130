import "../App.css";
import axios from "axios";

function useTwitter() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const postTweet = async (tweetText) => {
    const twitterToken = localStorage.getItem("twitter_access_token");
    let url_postTweet = urlEndpoint + "/twitter/post-tweet";
    try {
      const response = await axios.post(
        url_postTweet,
        JSON.stringify({
          tweetText,
          accessToken: twitterToken,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`,
          },
        }
      );

      console.log("Tweet posted successfully:", response.data);
      return response;
    } catch (error) {
      console.error("Error posting tweet:", error);
    }
  };
  const postTweetWithMedia = async (tweetText, imageBase64) => {
    let url_postTweet = urlEndpoint + "/twitter/post-tweet-with-media";
    const twitterToken = localStorage.getItem("twitter_access_token");
    try {
      const response = await axios.post(
        url_postTweet,
        JSON.stringify({
          tweetText,
          accessToken: twitterToken,
          imageBase64,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`,
          },
        }
      );

      console.log("Tweet posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting tweet:", error);
    }
  };

  const updateTwitterInfo = async (twitterSubject) => {
    let url_postTweet = urlEndpoint + "/twitter/update-twitter-info";
    try {
      const response = await axios.post(
        url_postTweet,
        {
          twitter: twitterSubject
            ? {
                name: twitterSubject.name,
                avatar: twitterSubject.profilePictureUrl,
                username: twitterSubject.username,
              }
            : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`,
          },
        }
      );
      console.log("Update twitter info successfully:", response.data);
    } catch (error) {
      console.error("Error update twitter:", error);
    }
  };

  return {
    postTweet,
    postTweetWithMedia,
    updateTwitterInfo,
  };
}
export default useTwitter;
